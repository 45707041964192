import React from 'react';
import ReactDOM from 'react-dom/client';
import LoginForm from './LoginPage';

const onSubmit = (username: string, password: string) => {
  let http: XMLHttpRequest = new XMLHttpRequest();
  let params: string = `code=${password}&username=${username}`;
  http.open('POST', '/api/auth', true);
  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  http.onreadystatechange = () => {
    if (http.readyState === 4 && http.status === 200) {
      switch (http.responseText) {
        case "ok": window.location.reload(); break;
        case "err": alert("Incorrect code"); break;
        case "user_err": alert("User error"); break;
        case "no": alert("Format Error"); break;
      }
    }
  }
  http.send(params);
}

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <React.StrictMode>
    <LoginForm onSubmit={onSubmit} />
  </React.StrictMode>
);