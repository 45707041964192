import React, { useState } from 'react';

import './LoginForm.css';
import './Card.css';

type LoginProps = {
    onSubmit?: (username: string, password: string) => void;
};

const Login: React.FC<LoginProps> = ({ onSubmit }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (onSubmit) onSubmit(username, password);
    };

    return (
        <div className="card">
            <form className="login-form" onSubmit={handleSubmit}>
                <input
                    className="login-input"
                    type="text"
                    placeholder="Mac"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                />
                <input
                    className="login-input"
                    type="code"
                    placeholder="MFA code"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <button className="login-submit" type="submit">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default Login;